import React from "react";

const Game = () =>{

    return(
        <div><h2>Game</h2></div>
    )
}


export default Game;