import React from "react";
import "../assets/Styles/about.css"
import "../assets/Styles/Allfonts.css";
import polygon from "../assets/img/Polygon.svg"
import polygon2 from "../assets/img/Polygon2.svg"
import { Link } from "react-router-dom";




const TearmsAndConditions = () => {
    return (

        <div className="About us container-fluid g-0 ">
            <div className="d-flex justify-content-center align-items-center about text-white backIcon">
                <h1 className="allFonts">Terms and Conditions</h1>
            </div>
            <div className="container mt-4 mb-5 TNC ">

                <p>This Terms and Conditions was last modified on September 1, 2023.</p>

                <p>
                    LEGAL NOTICE: PLEASE READ THESE TERMS AND CONDITIONS FOR USE (“TERMS”) BEFORE USING THE SERVICES.  THESE TERMS FORM A LEGAL AGREEMENT BETWEEN SPORTSNERVE SOLUTIONS PTE LTD , A COMPANY INCORPORATED UNDER THE LAWS OF SINGAPORE WITH ITS REGISTERED OFFICE AT 531A UPPER CROSS STREET, #04-98, HONG LIM COMPLEX,SINGAPORE (051531) (THE “LICENSOR”, “COMPANY”, “WE” “OUR” OR “US”) AND YOU ( THE “LICENSEE”, “YOU”,“YOUR” OR “USER” BEING A NATURAL PERSON EITHER IN AN INDIVIDUAL CAPACITY, OR IF ACCESSED OR USED BY OR FOR ANY COMPANY, AN AUTHORISED REPRESENTATIVE).  USE OF THESE SERVICES IS SUBJECT TO THE TERMS OF THE TERMS SET FORTH BELOW. <br />

                    "SERVICES" SHALL REFER TO THE SERVICES PROVIDED BY THE LICENSOR TO THE END USER ON www.sportsnerve.com INCLUDING THE RELATED MOBILE SITE AND MOBILE APPLICATION (“PLATFORM”) AND INCLUDING ANY FUNCTIONALITY OR DATA OR INFORMATION PROVIDED TO THE USER ON THE PLATFORM. <br />

                    BY EXECUTING THESE TERMS OR BY USING THE SERVICES, YOU AGREE TO THE FOLLOWING TERMS.  IF YOU DO NOT AGREE TO THE TERMS, DO NOT USE THE SERVICES. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS NOTICE, USING ANY OF THE SERVICES INDICATES YOUR ACCEPTANCE OF THESE TERMS.  <br />

                    THESE TERMS ALONG WITH THE NOTIFICATIONS AND COMMUNICATIONS THAT YOU RECEIVE THROUGH THE COMMUNICATION MODES SHALL GOVERN YOUR ACCESS TO AND USE OF SERVICES ON PLATFORM. BY MERELY USING, REGISTERING, ACCESSING THE PLATFORM, YOU AGREE AND ACCEPT ALL THE TERMS AND CONDITIONS CONTAINED HEREIN, AND THESE TERMS INCLUDING THE COMPANY’S POLICIES CONSTITUTE YOUR BINDING OBLIGATIONS TO THE COMPANY. BY USING THE PLATFORM, YOU AGREE TO THE FOLLOWING TERMS AND REPRESENT THAT YOU HAVE READ AND UNDERSTOOD ALL OF THE PROVISIONS OF THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE OR ACCESS THE PLATFORM. <br />

                    WHEN YOU USE ANY CURRENT OR FUTURE SERVICES PROVIDED BY US THROUGH THE PLATFORM YOU WILL BE SUBJECT TO THE RULES, GUIDELINES, POLICIES, TERMS AND CONDITIONS APPLICABLE TO SUCH SERVICES AND THEY SHALL BE DEEMED TO BE INCORPORATED INTO THE TERMS AND CONSIDERED A PART AND PARCEL OF THE SAME. WE RESERVE THE RIGHT, AT OUR SOLE DISCRETION, TO CHANGE, MODIFY, ADD, OR REMOVE PORTIONS OF THE TERMS AND CONDITIONS AT ANY TIME. WE WILL NOTIFY YOU ABOUT SUCH CHANGES THAT MAY MATERIALLY IMPACT YOUR USE OF THE PLATFORM. YOUR CONTINUED USE OF PLATFORM FOLLOWING THE CHANGES OR UPDATES WILL MEAN THAT YOU ACCEPT AND AGREE TO THE REVISIONS. ACCESSING, BROWSING, OR OTHERWISE USING THE PLATFORM INDICATES YOUR AGREEMENT WITH ALL THE TERMS AND CONDITIONS. PLEASE READ CAREFULLY BEFORE PROCEEDING. BY ACCEPTING, YOU ALSO ACCEPT AND AGREE TO BE BOUND BY ALL OF COMPANY’S POLICIES APPLICABLE TO YOU, AS AMENDED, FROM TIME TO TIME. <br />

                    YOU SHALL ABIDE BY THESE TERMS AT ALL TIMES WHILE USING THE PLATFORM. THESE TERMS CONSTITUTES AN ELECTRONIC RECORD, WHICH IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES, OR ANY OTHER FORM SUCH AS CLICKWRAP TO INDICATE YOUR ACCEPTANCE OF THESE TERMS. <br />

                </p>
                <h2>ELIGIBILITY</h2>

                <p>
                    To begin the enrolment process, you must complete the registration process for one or more of the Services provided on Platform and create an account/User ID. By registering on the Platform, you represent and warrant that -
                </p>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You can lawfully enter into and form contracts as per the applicable laws and that you are fully able, competent and authorized to enter into the terms, conditions, obligations, representations and warranties set forth in these TERMS.  </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>If you are a minor i.e. under the age of 18 years, you should get the assistance of a parent or legal guardian to use the Platform. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>If you register as a business entity, you represent that you are duly authorized by the business entity to accept the terms and conditions of these TERMS and you have the authority to bind the business entity to these TERMS and that the business entity is registered under the applicable laws. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Your use of Platform does not violate any applicable law or regulation. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You have not been previously removed from using the Service or Platform by Us or your account has not been terminated by Us. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You do not have any other fictitious account or User ID registered on the Platform. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>All the information provided by you is true, accurate, current and complete, and you will not hold us liable to verify any such information or your eligibility or authority to use the Platform or avail the Services. </p>
                </div>

                <h2>REGISTRATION OF USER ID</h2>

                <p>Registration on the Platform.</p>

                <p>
                    A User shall be able to avail the Services on the Platform only upon completing the registration process on the Platform. As part of the registration process, you understand and acknowledge that it is your responsibility to get complete information of the Services offered by Company, before availing the same and you are required to first register with the Platform, by using the new register/sign up option available at the Platform. You must provide us with your (or your business') legal name, address, phone number, e-mail address, as well as any other information we may request.
                </p>

                <h2>USE OF SERVICE</h2>
                <p>Grant of license. The Company hereby grants to the User during the Subscription Term, a fixed-term, non-exclusive, non-transferable, revocable, non-sub licensable, worldwide, limited license to use and access the Platform and/or Services for the User’s internal use only and in accordance with the terms and conditions of these TERMS. Nothing herein contained shall be construed as granting to the User any intellectual property right, which includes copyrights, regarding the Platform and Services except as expressly provided for hereunder. Company reserves all rights not expressly granted herein. Use of the Platform for any purpose not expressly permitted by these TERMS is strictly prohibited.</p>

                <h2>ACCESS TO PLATFORM</h2>
                <p>Company does not guarantee availability of the Platform at all times. We shall take reasonable efforts to make the Services available to you, at all times through the Platform. However, as the Services are provided over the internet, data and cellular networks, the quality and availability of the same may be affected by factors outside Company’s control. Therefore, we shall not be liable for non-availability of the Services at any time. We may try and restore access to the Platform and the Services on a reasonable and commercially viable basis.</p>

                <h2>ACCOUNT MANAGEMENT</h2>
                <h6>Account Information</h6>

                <p>You agree that if you provide information which is untrue, inaccurate, not current, or incomplete or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current, incomplete, or not in accordance with these TERMS, we shall have the right to suspend or terminate your account on the Platform or indefinitely block you from accessing the Platform.</p>

                <h6>User ID and password</h6>
                <p>
                    User shall be responsible for maintaining the confidentiality of its User ID and its password and User agrees to accept responsibility for all activities that are carried out under its User ID and of the Services in its name. We encourage User to use strong passwords i.e. passwords that use a combination of upper and lower case letters, numbers and symbols with its User ID.
                </p>

                <h6>Unauthorized use of User ID.</h6>
                <p>You must (i) notify Company immediately in case of any breach of security or unauthorized use of your User ID; (ii) report to Company immediately and use reasonable efforts to stop any unauthorized use of the Services that is known or suspected by you, and (iii) not provide false identity information to gain access to another user’s ID to use the Services. You agree to abide by all applicable laws and regulations. You shall be solely responsible for your acts and omissions. The Company shall not be responsible for any misuse or unauthorized use of your User ID and loss of data or functionality occasioned due to such unauthorized use.</p>

                <h2>PAYMENTS</h2>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Users of the Platform acknowledge and agree to pay the platform usage fees ("Platform Fees") as outlined on the website or through a separate agreement. The Platform Fees may vary based on the type of services, subscriptions, or transactions conducted through Platform. The specific fee structure applicable to each transaction or service will be clearly communicated to Users. Platform Fees may be billed on a one-time basis, recurring basis, or as otherwise specified. The billing cycle and frequency will be communicated to Users in advance. </p>
                </div>



                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Users are responsible for any applicable taxes related to the Platform Fees. The Platform Fees quoted may be exclusive of taxes, and Users agree to pay any such taxes imposed on the transactions. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>The Company reserves the right to modify the Platform Fees at any time. Unless otherwise stated, Platform Fees are non-refundable.  </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> The Company reserves the right to suspend or terminate User access to the Platform for non-payment of Platform Fees. The Company reserves the right, at its sole discretion, to determine the restoration of access, which may be contingent upon the settlement of outstanding fees.</p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Where applicable to any User, you can elect to pay for the Services through various modes of payment: (i) Apple/Google pay, direct bank transfer and Debit & Credit Card; (ii) E-Wallets; Any other mode of payment as may be acceptable to the Company. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the lack of authorization for any transaction, on account of a You or User having exceeded the credit limit with his/her bank or for any payment issue arising out of the transaction.</p>
                </div>


                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> Use of the payment facilities available on the Platform shall not render Company liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties or fraud as regards the Services purchased through the Platform. Company shall not be responsible for any damages, interests or claims arising from a transaction being unprocessed.</p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>The Platform utilizes a payment gateway for online payments. Every User who elects to pay online using such payment gateway hereby agrees to provide accurate information, such as credit/debit card information for purchasing Services on the Platform.  Every User further warrants that he/she shall not use any payment information or instrument that is not lawfully owned by the User. The Platform disclaims all liabilities arising out of loss of any information pertaining to the confidentiality of the credit/debit card details or any pre-paid instruments. In addition to these Terms, the terms and conditions of the concerned bank or other financial institution shall also be applicable to every User. The Platform disclaims any liability arising out of the failure or delay to process payments by such banks or financial institutions. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>If Company terminates your account; or if you close your account, or if the payment of your order cannot be completed for any reason, you shall be obligated to pay Company for all unpaid fees plus any penalties, as may be applicable. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>The Platform is merely a facilitator for providing the Users with payment channels through automated online electronic payments, collection and remittance facility for the payment of Services purchased by the Users on the Platform using the existing authorized banking infrastructure and online payment gateway networks. </p>
                </div>

                <h2>NO REFUND NO CANCELLATION</h2>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>By accessing and using the Platform, every User agrees to abide by the terms and conditions of this No Refund and No Cancellation clause. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> All transactions conducted through the Platform are final. The Company does not provide refunds for any payments made by the Users for any Services, or subscriptions purchased through the Platform.</p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> The Company is not liable for any interruptions or disruptions of Services, and refunds will not be provided for any such interruptions, regardless of the duration.
                    </p>
                </div>
                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Users are not allowed to cancel any transactions, subscriptions, or Services once initiated on the Platform. </p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> For subscription-based Services, Users are committed to the entire subscription period. Cancellation during the subscription term is not permitted, and payments including the subscription fees are non-refundable.</p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> The Company reserves the right to consider exceptions in cases of documented technical errors resulting in overcharges or other billing discrepancies. Users must promptly report such issues to the Company.</p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> The Company, at its sole discretion, may choose to make exceptions to this Clause in extenuating circumstances. Any such exceptions do not create an ongoing obligation to provide similar exceptions in the future.</p>
                </div>

                <div className="d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>By continuing to use the Platform, User acknowledges that it has read, understood, and agreed to the terms of this No Refund and No Cancellation Clause.</p>
                </div>

                <h2>COMMUNICATIONS</h2>
                <p>When you use the Platform or send emails, other data, information, or communication to us, you agree and understand that you are communicating with us through electronic records and you consent to receive communications via electronic records from us periodically or as and when required. We may communicate with you by email or any other mode of communication, electronic or otherwise.</p>

                <h2>USE OF THE PLATFORM</h2>
                <p>You agree, undertake and confirm that your use of the Platform shall be strictly governed by the following binding principles:</p>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You shall not host, display, upload, modify, publish, transmit, update or share any information or image which:</p>


                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>belongs to another person and over which you have no right</p>
                </div>

                <div className="d-flex polygon2">
                    <img src={polygon2} alt="" />
                    <p>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating to or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever, or unlawfully threatening or harassing, including but not limited to ‘indecent representation of women’.</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> is false, inaccurate or misleading in any way.</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> harasses or advocates harassment of another person.</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>involves the transmission of ‘junk mail’, ‘chain letters’, unsolicited mass mailing, or ‘spamming’. </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>promotes illegal activity or conduct that is abusive, threatening, obscene, defamatory, or libelous. </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>infringes upon or violates any third party's rights [including but not limited to intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address, or phone number) or rights of publicity]. </p>
                </div>


                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>promotes an illegal or unauthorized copy of another person's copyrighted work such as providing pirated computer programs or links, information to circumvent manufacturer-installed copy-protect devices. </p>
                </div>


                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> contains restricted or password-only access pages, hidden pages or images or URLs leading to any other pages (those not linked to or from another accessible page).</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone. </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, providing or creating computer viruses. </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>contains unauthorized videos, photographs or images of another person (whether a minor or an adult. </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> tries to gain unauthorized access or exceeds the scope of authorized access to the Platform, profiles, blogs, communities, account information, bulletins, friend requests, or other areas of the Platform, or solicits passwords or personal identifying information for commercial or unlawful purposes from other users on the Platform.</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> solicits gambling or engages in any gambling activity which we, at our sole discretion, believe is or could be construed as being illegal.</p>
                </div>


                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>  interferes with another’s use and enjoyment of Platform.</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> refers to any website/URL which, at our sole discretion, contains material that is inappropriate for Platform or any other website and content that is prohibited or violates the letter and spirit of these TERMS.</p>
                </div>


                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> harms minors in any way.</p>
                </div>


                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>infringes any patent, trademark, copyright, proprietary rights, third-party’s trade secrets, rights of publicity, or privacy, is fraudulent, or involves the sale of counterfeit or stolen items. </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>violates any law for the time being in force. </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> deceives or misleads the addressee/ users about the origin of messages or communicates any information which is grossly offensive or menacing in nature.</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>impersonates another person. </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>v)	contains software viruses or any other computer codes, files, or programs designed to interrupt, destroy, or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs, or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept, or expropriate any system, data, or personal information. </p>
                </div>


                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You shall not, directly or indirectly, offer or attempt to offer trade or attempt to trade in any item which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</p>


                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You shall not attempt to gain unauthorized access to any portion or feature of the Platform, other systems, networks connected to the Platform, server, computer, network, or the services offered on or through the Platform by hacking, password ‘mining’, or any other illegitimate means.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You shall not make any negative, denigrating, or defamatory statement(s)/comment(s) about Company, the brand name or domain name used by Company, or otherwise engage in any conduct or action that might tarnish the image or reputation of Company or other Users on the Platform or otherwise tarnish or dilute any Company trademark, service marks, trade name and/or goodwill associated with such trade, service marks or trade name as may be owned or used by Company.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform, any transaction being conducted on the Platform or any other person’s use of the Platform.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You shall enable Company to use the information you provide Company with, so that Company does not violate any rights you might have in your information. You agree to grant Company a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, and sub-licensable (through multiple-tiers) right to exercise the copyright, publicity or database rights or any other rights you have in your information for any current or future promotional or advertising related activities and any business purposes of Company. Company will only use your information in accordance with these TERMS.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You understand that we have the right at all times to disclose any information (including the identity of the persons who have provided information or material on the Platform) as necessary to satisfy any law, regulation, or valid governmental request, or in connection with the investigation of an alleged illegal activity or its solicitation and/or response to a lawful court order. </p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>We reserve the right, but have no obligation, to monitor the material posted on the Platform. Company shall have the right, at its sole discretion, to remove any content that violates or is alleged to violate any applicable law or either the spirit or letter of the TERMS. YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIAL YOU POST ON THE PLATFORM AND YOUR INDEPENDENT COMMUNICATION WITH THE OTHER USERS, REGARDLESS OF FORM. Please be advised that such content posted does not reflect Company’s views. In no event shall the Company assume or have any responsibility or liability for any content posted on the Platform or claims, damages, or losses resulting from its use and/or appearance of it on the Platform.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You understand that we have the right at all times to disclose any information (including the identity of the persons who have provided information or material on the Platform) as necessary to satisfy any law, regulation, or valid governmental request, or in connection with the investigation of an alleged illegal activity or its solicitation and/or response to a lawful court order. </p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>We reserve the right, but have no obligation, to monitor the material posted on the Platform. Company shall have the right, at its sole discretion, to remove any content that violates or is alleged to violate any applicable law or either the spirit or letter of the TERMS. YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIAL YOU POST ON THE PLATFORM AND YOUR INDEPENDENT COMMUNICATION WITH THE OTHER USERS, REGARDLESS OF FORM. Please be advised that such content posted does not reflect Company’s views. In no event shall the Company assume or have any responsibility or liability for any content posted on the Platform or claims, damages, or losses resulting from its use and/or appearance of it on the Platform.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>It is possible that other Users (including unauthorized persons or ‘hackers’) may post or transmit offensive or obscene material on the Platform and that you may be involuntarily exposed to such material. It is also possible for others to obtain personal information about you due to your use of the Platform and use such information to harass or injure you. We do not approve of such unauthorized uses but by using the Platform, you acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the Platform. Please carefully select the type of information that you publicly disclose or share with others on the Platform.</p>
                </div>

                <h2>USE OF THE PLATFORM</h2>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>All text, graphics, visual interfaces, photographs, trademarks, logos, sounds, music and artwork, notes, messages, emails, billboard postings, drawings, profiles, opinions, ideas, images, videos, audio files, other material or information (collectively ‘Content’) are third-party generated Content and Company has no responsibility or liability over such third-party generated Content as Company is merely an intermediary for the purposes of these TERMS. Except as expressly provided in the TERMS, no part of the Platform including the Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including ‘mirroring’) to any other computer, server, website or other medium for publication, distribution or any commercial enterprise without Company’s prior written consent.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You may use the information of the Products made available on the Platform for downloading provided you:</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p> do not remove any proprietary notice language in all copies of such Content;</p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>use such Content only for your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it to any media; </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>make no modifications to any Content; and </p>
                </div>

                <div className="d-flex polygon2 ">
                    <img src={polygon2} alt="" />
                    <p>do not make any additional representations or warranties relating to the Content. </p>
                </div>




                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>You shall be responsible for the Content posted or transmitted on the Platform by you. The Content will become our property and you grant us the worldwide, perpetual, royalty free and transferable rights in such Content (i) to display your Content on the Platform; and (ii) to use it for analytical purpose; and (iii) to host, copy, transmit, analyse, process, store, configure, publish and commercially use the Content, solely as necessary to provide you the Services; and (iv) to exchange it with the marketplace through the interface the Platform supports; and (v) to share required order and/or product details with fulfilment centre or other network partners in order to provide the required Service successfully. We shall be entitled to, consistent with our privacy policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of purpose forever, including, but not limited to, promotional and advertising purposes in any media, whether now known or hereafter devised or the creation of derivative work. You agree that any Content you post may be used by us, consistent with these TERMS, and you are not entitled to any payment or other compensation for such use.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Representation as to Content: You represent, warrant, and agree that no Content/details shared by you with Platform violates or infringes upon the rights of any third party, including any patent, copyright, trademark, privacy, publicity, or other personal or proprietary rights, breaches or conflicts with any obligation, such as a confidentiality obligation, or contains libellous, defamatory, or otherwise unlawful material.
                        In addition to this, you shall ensure that the Content does not (i) include anything that actually or potentially infringes or misappropriates the copyright, trade secret, trademark or other intellectual property right of any third party; and  (ii) misrepresents or defrauds the other users as to your identity; and (iii) contains anything that is obscene, defamatory, harassing, offensive, malicious, harmful, abusive, invasive of privacy, defamatory, threatening, hateful or otherwise discriminatory, false or misleading, or incites an illegal act; and (iv) is free from any virus, Trojan etc.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> Your responsibility for your Content: At no point of time, shall we take any responsibility for the Content that you upload through the Platform and you shall indemnify and hold us harmless for any claims related to your Content, including, but not limited to any infringing Content, any errors or omissions in Content, or for any loss or damage of any kind incurred as a result of the use of any Content posted, transmitted, linked from, or otherwise accessible through or made available via the Platform. If you violate the TERMS, your User ID will stand terminated and its use thereof shall be permanently suspended. Notwithstanding the foregoing, to the extent applicable by law, we or another defrauded user reserve the right to proceed in legal action against you, in the event of breach under this clause of the TERMS.
                    </p>
                </div>



                <h2>PROPREITARY RIGHTS</h2>
                <p>
                    There may be proprietary logos, service marks and trademarks found on the Platform whether owned/used by us or otherwise. By displaying them on the Platform, we are not granting you any license to utilize those proprietary logos, service marks, or trademarks. Company owns all rights in any intellectual property rights created or developed by Company and exclusively owns all rights (including intellectual property rights), title, interest in respect of the Platform.


                </p>

                <p>
                    All Content on the Platform, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks and other intellectual property rights. You must not copy, reproduce, republish, upload, post, transmit, or distribute Company’s material in any way, including by email or other electronic means and whether, directly or indirectly, you must not assist any other person to do so. Without the prior written consent of the owner, modification or use of the material on any other website/networked computer environment or for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks, and other proprietary rights is prohibited. Any rights not expressly and unequivocally granted to the User are reserved. Any use for which you receive any remuneration, whether money or otherwise, is a commercial use for the purposes of this clause
                </p>

                <h2>DISCLAIMER</h2>

                <p>
                    YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE PLATFORM AND THE SERVICE IS ENTIRELY AT YOUR OWN RISK AND THAT THE PLATFORM AND THE SERVICES THEREIN ARE PROVIDED ON AN "AS IS" OR "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. ALL EXPRESS AND IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS ARE EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW. TO THE FULLEST EXTENT PERMITTED BY LAW, COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM, THE SERVICES, THE PRODUCTS DISPLAYED ON THE PLATFORM AND YOUR USE OF THE SAME THEREOF. COMPANY DOES NOT WARRANT THAT PRODUCT DESCRIPTION OR OTHER CONTENT ON PLATFORM IS ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE AND ASSUMES NO LIABILITY IN THIS REGARD.

                </p>

                <p>
                    COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES OR THE PLATFORM'S CONTENT OR THE DETAILS AND CONTENT SHARED BY OTHER USERS ON THE PLATFORM OR THE CONTENT OF ANY THIRD PARTY WEBSITES LINKED TO THE PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE PLATFORM, SERVICE AND SOFTWARE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR PLATFORM AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL, TECHNICAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, COMMUNICATED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM OR THE SERVICE. COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY SERVICE OR ANY TRANSACTION BETWEEN YOU AND/OR OTHER USERS. COMPANY SHALL NOT BE RESPONSIBLE FOR SERVICE DISRUPTION CAUSED DUE TO LOSS OF INTERNET CONNECTION BETWEEN USER AND THE PLATFORM AND THE IMPACT ON THE USAGE OF USER.


                </p>

                <p>
                    You also agree not to interfere with or attempt to gain unauthorized access to any parts of Platform or any accounts, computer systems or networks, etc. You understand and acknowledge that the Platform is prone to unintentional inaccuracies in Content. E.g. typographical errors, improper linkages etc.
                </p>


                <h2>TECHNICAL SUPPORT</h2>
                <p>The Company shall have a mechanism of ‘technical support’ for your assistance in using the Platform. This ‘technical support’ may be a bot and may further be escalated to the employees of the Company, upper management and finally to the Company’s board of complaint resolution. You agree that you will not approach any court or institute any proceedings or commence any dispute resolution in relation to the Services or the Platform, unless you have utilized this feature on the Platform and exhausted all levels of escalation.</p>

                <h2>HYPERLINKS</h2>

                <p>The Platform may from time to time contain hyperlinks to other websites or mobile applications.  Such links are provided for convenience only and we take no responsibility for the content and maintenance of or privacy compliance of any linked website.  Any hyperlink on our Platform to another website does not imply our endorsement, support, or sponsorship of the operator of that website nor of the information and/or services which it provides. The Company is not a party to any transaction between you and such website.</p>

                <h2>LIMITATION OF LIABILITY</h2>

                <p>IN NO EVENT SHALL COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE FOR DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF PLATFORM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM ANY ASPECT OF YOUR USE OF THE PLATFORM OR THE SERVICE, INCLUDING WITHOUT LIMITATION WHETHER THE DAMAGES ARISE FROM USE OR MISUSE OF THE PLATFORM OR THE SERVICE OR THE CONTENT POSTED ON YOUR USER ID, FROM INABILITY TO USE THE PLATFORM OR THE SERVICE, OR THE INTERRUPTION, SUSPENSION, MODIFICATION, ALTERATION, OR TERMINATION OF THE PLATFORM OR THE SERVICE. SUCH LIMITATION OF LIABILITY SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER SERVICES OR RENDERED THROUGH OR ADVERTISED IN CONNECTION WITH THE PLATFORM OR THE SERVICES THROUGH THE USER’S ID OR ANY LINKS ON THE PLATFORM. THESE LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE LIABLE FOR USER DETAILS AND CONTENTS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY USER OR THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU. THE FOREGOING LIMITATIONS OF LIABILITY SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED WARRANTY OR REMEDY HEREIN.</p>

                <h2>INDEMNITY</h2>

                <p>You shall indemnify and hold harmless Company, its licensees, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees from any claim, demand, or actions including reasonable attorneys' fees made by any third party or penalty imposed due to or arising out or relating to: (i) details and Content that you submit or transmit for the Services; and (ii) your violation of any rights of any other person in connection with the Platform; and (iii) infringement of any third party intellectual property rights; (iv) any breach of the terms and conditions of these TERMS; (v) any breach of applicable law or violation of any laws and regulations.</p>

                <h2>PRIVACY POLICY</h2>
                <p>
                    The Company is committed to responsibly handle the information and data it collects through the Platform in compliance with the Privacy Policy located at <Link to={'https://www.sportsnerve.com'}>www.sportsnerve.com</Link>
                </p>


                <h2>SURVIVAL</h2>

                <p>Provisions such as, disclaimer of warranties, limitation of liability, indemnity, ownership of intellectual property, confidentiality, miscellaneous terms, prohibited conduct shall survive any termination.</p>

                <h2>MISCELLANEOUS TERMS</h2>


                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Choice of Law and Jurisdiction: These TERMS shall be construed and governed by the laws of Singapore. Parties further agree that the courts in Singapore shall have an exclusive jurisdiction over such disputes </p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Force Majeure: Without limiting the foregoing, under no circumstances shall the Company be held liable for any damage or loss due to deficiency in performance of Services resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without limitation, internet failures, computer equipment failures, change in law, telecommunication equipment failures, software failures, machinery breakdown, strike, or due to government regulations, floods, storms, electrical failure, civil disturbances, epidemic, pandemics and any consequences thereof; quarantine lockdowns, riots and any other reasons beyond reasonable control of the Company </p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> No waiver:The Company’s failure to insist on or enforce strict performance of any of the terms of this TERMS shall not be construed as a waiver of any provision or right.</p>
                </div>



                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> Severability: If any part of these TERMS is held to be invalid or unenforceable by any law or regulation or final determination of a competent court or tribunal, that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions of these TERMS.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p> No agency relationship: You agree that no joint venture, employment, or agency relationship exists between you and the Company as a result of these TERMS or from your use of the Platform. You shall not make any statement or enter into any commitment, agreements or arrangement on behalf of the Company, unless you are authorized to do so.</p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Entire agreement: These TERMS and Privacy Policy and other policies notified by the Company constitute the entire agreement between you and the Company relating to this subject matter and supersede any and all prior communications and/or agreements between You and the Company relating to this subject matter.
                    </p>
                </div>

                <div className=" d-flex polygon ">
                    <img src={polygon} alt="polygon" />
                    <p>Contact Us: Please send any comments or questions, including all enquiries not related to trademark and copyright infringement, on the website to <Link to={'https://sportsnerve.com/'} >www.sportsnerve.com</Link>  or mail us at  <a href="mailto:support@sportsnerve.com?subject=Question&body=Hello,">support@sportsnerve.com</a> .
                    </p>
                </div>


            </div>


        </div>

    )
}

export default TearmsAndConditions;