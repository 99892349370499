// baseUrl.js

export const BaseUrl = () => {
    return  "https://apidev.sportsnerve.com";
};

// export const BaseUrl = () => {
//     return "http://localhost:6756";
// }


// export const BaseUrl = () => {
//     return  "https://apps.sportsnerve.com";
// };
