import React from "react";

const Tournament = () =>{

    return(
        <div><h2>Tournament</h2></div>
    )
}


export default Tournament;